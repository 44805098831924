import styled from 'styled-components';

export const BaseInput = styled.input`
  display: block;
  width: 100%;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.base.border.main};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.base.bg.main};

  &:disabled {
    color: ${({ theme }) => theme.colors.base.text.sub};
    background-color: ${({ theme }) => theme.colors.base.bg.body};
  }
`;
