import { FC, ReactNode } from 'react';
import styled from 'styled-components';

export type HeadingProps = {
  children: ReactNode;
};

const H1 = styled.h1`
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 24px;
  text-align: center;
`;

export const Heading: FC<HeadingProps> = ({ children }) => <H1>{children}</H1>;
