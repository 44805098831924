import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const IconButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.base.text.sub};
  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;
