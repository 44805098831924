import { Store } from 'react-notifications-component';
import type { NOTIFICATION_TYPE } from 'react-notifications-component';
import { MessageWithIcon } from '@components/ui/Heading/MessageWithIcon';

export const notifySuccess = (message: string) => {
  const notificationType: NOTIFICATION_TYPE = 'success';

  Store.addNotification({
    message: (
      <MessageWithIcon message={message} notificationType={notificationType} />
    ),
    type: notificationType,
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__bounceInRight'],
    animationOut: ['animate__animated', 'animate__bounceOutRight'],
    dismiss: {
      duration: 5000,
      waitForAnimation: true,
      showIcon: true,
    },
  });
};

export const notifyError = (message: string) => {
  const notificationType = 'danger';
  Store.addNotification({
    message: (
      <MessageWithIcon message={message} notificationType={notificationType} />
    ),
    type: notificationType,
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__bounceInRight'],
    animationOut: ['animate__animated', 'animate__bounceOutRight'],
    dismiss: {
      duration: 5000,
      waitForAnimation: true,
      showIcon: true,
    },
  });
};
