import { ReactNode, FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

export type SecondaryButtonProps = {
  children?: ReactNode;
  disabled?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

const Button = styled.button`
  --button-height: 40px;
  border: solid 1px ${({ theme }) => theme.colors.base.text.sub};
  border-radius: calc(var(--button-height) / 2);
  color: ${({ theme }) => theme.colors.base.text.sub};
  height: var(--button-height);
  min-width: 160px;
  padding: 0 32px;
  text-align: center;
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.primary.main};
  }
  &:active {
    border-color: ${({ theme }) => theme.colors.primary.light};
    color: ${({ theme }) => theme.colors.primary.light};
  }
  &:disabled {
    border-color: ${({ theme }) => theme.colors.base.border.main};
    color: ${({ theme }) => theme.colors.base.border.main};
  }
`;

export const SecondaryButton: FC<SecondaryButtonProps> = ({
  children,
  disabled,
  onClick,
}) => (
  <Button type={'button'} disabled={disabled} onClick={onClick}>
    {children}
  </Button>
);
