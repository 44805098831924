import { ReactNode, HTMLAttributes, FC } from 'react';
import styled from 'styled-components';

export type PrimaryButtonProps = {
  children?: ReactNode;
  disabled?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

const Button = styled.button`
  --button-height: 40px;

  background-color: ${({ theme }) => theme.colors.primary.main};
  border-radius: calc(var(--button-height) / 2);
  box-shadow: ${({ theme }) => theme.shadow.primary.main};
  color: ${({ theme }) => theme.colors.base.bg.main};
  font-weight: bold;
  height: var(--button-height);
  min-width: 160px;
  padding: 0 32px;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
    box-shadow: ${({ theme }) => theme.shadow.primary.hover};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.base.border.main};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.base.text.sub};
    cursor: not-allowed;
  }
`;

export const PrimaryButton: FC<PrimaryButtonProps> = ({
  children,
  disabled,
  onClick,
}) => (
  <Button disabled={disabled} onClick={onClick}>
    {children}
  </Button>
);
