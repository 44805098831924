import styled from 'styled-components';

export const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0 auto;
  padding: 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    border: 0;
    width: 100%;
  }
`;
