import { FC } from 'react';
import type { NOTIFICATION_TYPE } from 'react-notifications-component';
import {
  faCheckCircle,
  faInfoCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
`;

const Message = styled.p`
  font-weight: bold;
  margin-left: 16px;
`;
export const MessageWithIcon: FC<{
  message: string;
  notificationType: NOTIFICATION_TYPE;
}> = ({ message, notificationType }) => (
  <>
    <FlexWrapper>
      <Icon
        icon={
          notificationType === 'success'
            ? faCheckCircle
            : notificationType === 'danger'
            ? faTimesCircle
            : faInfoCircle
        }
      />
      <Message>{message}</Message>
    </FlexWrapper>
  </>
);
