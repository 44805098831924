import { css } from 'styled-components';

export const tableCellStyles = css`
  --cell-padding: 16px;
  padding: var(--cell-padding);
  border-right: 1px solid ${({ theme }) => theme.colors.base.border.main};
  text-align: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    position: relative;
    display: block;
    border-bottom: 1px solid ${({ theme }) => theme.colors.base.border.main};
    text-align: right;
    border-right: none;
    &::before {
      content: attr(data-label);
      font-weight: bold;
      position: absolute;
      left: var(--cell-padding);
    }
    &:last-child {
      border-bottom: 0;
    }
  }
`;
