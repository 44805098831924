import { forwardRef } from 'react';
import styled from 'styled-components';

export type ToggleButtonProps = React.InputHTMLAttributes<HTMLInputElement>;

const Label = styled.label`
  width: 56px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.base.border.main};
  position: relative;
  display: inline-block;
  border-radius: 46px;
  transition: 0.4s;
  box-sizing: border-box;

  &:after {
    content: '';
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    left: 2px;
    top: 2px;
    z-index: 2;
    background-color: ${({ theme }) => theme.colors.base.bg.main};
    box-shadow: ${({ theme }) => theme.shadow.base.main};
    transition: 0.1s;
  }
`;

const Input = styled.input`
  display: none;

  &:checked + ${Label} {
    background-color: ${({ theme }) => theme.colors.primary.main};
    &:after {
      box-shadow: ${({ theme }) => theme.shadow.primary.main};
      left: 26px;
    }
  }
`;

export const ToggleButton = forwardRef<HTMLInputElement, ToggleButtonProps>(
  function ToggleButton(props, ref) {
    return (
      <>
        <Input {...props} type="checkbox" ref={ref} />
        <Label htmlFor={props.id} />
      </>
    );
  }
);
