import styled from 'styled-components';

export const TableRow = styled.tr<{ even?: boolean }>`
  background-color: ${({ theme, even }) =>
    even ? theme.colors.base.bg.body : theme.colors.base.bg.main};
  border: 1px solid ${({ theme }) => theme.colors.base.border.main};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: block;
    margin-bottom: 16px;
  }
`;
