import styled from 'styled-components';

export const TableHeader = styled.thead`
  > tr {
    background-color: ${({ theme }) => theme.colors.base.bg.body};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: none;
  }
`;
