import { ChangeEvent, FC, useCallback } from 'react';
import styled from 'styled-components';

export type RadioButtonProps = {
  leftValue: string;
  leftLabel: string;
  rightValue: string;
  rightLabel: string;
  currentValue: RadioButtonProps['leftValue'] | RadioButtonProps['rightValue'];
  name: string;
  setParentState?: (nextValue: string) => void;
};

const Wrapper = styled.div`
  display: flex;
  border: solid 1px ${({ theme }) => theme.colors.base.border.main};
  border-radius: 8px;
  height: 40px;
  justify-content: center;
  max-width: ${({ theme }) => theme.breakpoints.xsmall};
  overflow: hidden;
`;

const Label = styled.label<{ checked: boolean }>`
  display: block;
  padding: 8px;
  text-align: center;
  width: 100%;
  cursor: default;

  ${({ checked, theme }) =>
    checked
      ? `color: ${theme.colors.primary.main};
          font-weight: bold;
          &:hover {
            cursor: default;
          }`
      : `color: ${theme.colors.base.text.sub};
          &:hover {
            color: ${theme.colors.primary.main}
          }`}
`;

const RadioInput = styled.input`
  display: none;
`;

const Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.base.border.main};
`;

export const RadioButton: FC<RadioButtonProps> = ({
  leftValue,
  leftLabel,
  rightValue,
  rightLabel,
  currentValue,
  name,
  setParentState,
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (setParentState) {
        setParentState(event.target.value);
      }
    },
    [setParentState]
  );

  return (
    <Wrapper>
      <Label
        htmlFor={`${name}_${leftValue}`}
        checked={currentValue === leftValue}
      >
        {leftLabel}
        <RadioInput
          type="radio"
          id={`${name}_${leftValue}`}
          value={leftValue}
          name={name}
          onChange={handleChange}
          checked={currentValue === leftValue}
        />
      </Label>
      <Line />
      <Label
        htmlFor={`${name}_${rightValue}`}
        checked={currentValue === rightValue}
      >
        {rightLabel}
        <RadioInput
          type="radio"
          id={`${name}_${rightValue}`}
          value={rightValue}
          name={name}
          onChange={handleChange}
          checked={currentValue === rightValue}
        />
      </Label>
    </Wrapper>
  );
};
